section#booking {

	@include tight-row;
	margin: $grid-gutter-width 0 0 0;

	> .inner {

		@include make-row(0);
		border-radius: $border-radius-extra;
		background: $color6;
		box-shadow: $box-shadow-base;
		overflow: hidden;
		padding: ($grid-gutter-width / 2);

		p {
			.label {
				font-weight: bold;
			}
			&.booking_infos {
				overflow: auto;
				zoom: 1;
				line-height: 1.5em;
				.label {
					float: left;
					width: 15%;
					text-align: right;
					margin-right: 2%;
				}
			}
		}



	}

}