// --------------------------------------------------------------------------------------------
// HOME
// --------------------------------------------------------------------------------------------

.homepage {

	.block-main {
		padding-bottom: 0;
	}

	.illustration {

		// Background-image is dynamically load in inline style in the view
		background: none no-repeat center $center-bg;
		background-size: cover;
		padding: 25px 0;

		@include breakpoint(mobileonly) {
			background-size: auto 100%;
		}

	}

	.block-infos {
		padding: 2%;
		width: 100%;
		text-align: center;
		background-color: $color8;

		.container {
			padding: 0 3%;

			.infos-title {
				@include font-size(25);
				font-family: $font3;
				color: #fff;
				line-height: 1em;
				text-transform: uppercase;
			}

			.infos-details {
				@include font-size(22);
				font-family: $font0;
				color: #fff;
				line-height: 1em;
			}
		}


	}

	.block-search {

		> .inner {
			margin: 2em auto;
		}

		// Search
		//-----------------------------------------------------
		.caption {

			@include font-size(23);
			background: $color3;
			text-align: center;
			font-family: $font1;
			color: white;
			text-transform: uppercase;
			line-height: 1em;
			padding: .3em;

		}

		.form {

			background: rgba(white, .9);
			padding: 1rem;
			margin: 0 0 2em;

		}

		div.localisation .control-label {
			@extend %legend_label;
		}

		.nights {
			@include font-size(14);
			float: right;
			color: black;
			display: block;
			font-weight: bold;
			line-height: 1em;
		}

		.btn-submit {
			@include font-size(28);
			line-height: 1em;
		}
	} // block-search end

	// Arguments
	//-----------------------------------------------------
	.arguments {

		.item {
			padding-bottom: ($grid-gutter-width / 2);
			min-height: 50px;
		}

	}

	.cities-container,
	.arguments-container {
		background-color: white;
	}

	.block-cities {

		padding: $grid-gutter-width 0;
		background: white;

		.main-title {

			@include font-size(30);
			font-family: $font1;
			color: $color4;
			width: 60%;
			line-height: 1em;
			margin: 0 auto .8em;
			text-align: center;
			position: relative;
			height: 0;
			border: 20px solid $color5;
			border-left-color: transparent;
			border-right-color: transparent;

			> span {
				display: block;
				margin-top: -12px;
				text-align: center
			}

		}

		.cities-list {

			padding: 0 ($grid-gutter-width / 2);

			.wrapper {
				@include make-row;
			}

			.item {

				@include make-sm-column(4);
				padding-bottom: ($grid-gutter-width / 2);

				> .inner {
					@include transition;
					background: white;
					border-radius: 4px;
					box-shadow: $box-shadow-base;
				}

				.picture {

					display: block;
					position: relative;
					background: darken($color5,15%);

					img {
						@include img-responsive;
					}

					.name {
						@include font-size(24);
						@include transition;
						font-family: $font1;
						color: white;
						line-height: 1em;
						position: absolute;
						width: 100%;
						bottom: 0;
						left: 0;
						padding: .3rem .7rem;
						background: $color2;
					}

					.coming-soon {

						@include font-size(24);
						font-family: $font1;
						color: $color4;
						width: 90%;
						line-height: 1em;
						margin: 0 auto .8em;
						text-align: center;
						position: absolute;
						top: 40%;
						left: 5%;
						height: 0;
						border: 20px solid $color5;
						border-left-color: transparent;
						border-right-color: transparent;

						> span {
							display: block;
							margin-top: -12px;
							text-align: center
						}

					}

				}

				.available-rooms {
					@include font-size(14);
					color: $color4;
					line-height: 1em;
					padding: .8rem;
				}

				&.item-1,
				&.item-5 {
					.picture {
						.name {
							background-color: $color2;
						}
					}
				}
				&.item-2,
				&.item-6 {
					.picture {
						.name {
							background-color: $color1;
						}
					}
				}

				@include hover {

					> .inner {
						box-shadow: $box-shadow-base-hover;
					}

					.picture {

						.name {
							padding-bottom: 1.2rem;
						}

					}


				}

			}

		}

	} // block-cities end

	// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
	// BREAKPOINTS
	// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------

	// Mobile only
	// ------------------------------------------------------------------------------------
	@include breakpoint(mobileonly) {

		.block-cities {

			.main-title {
				width: 90%;
			}

		}

		.reinit_search {
			padding-bottom: 1rem;
			padding-top: 0;
		}

	}

	// tablet-portrait
	// ------------------------------------------------------------------------------------
	@include breakpoint(tablet-portrait) {

		.block-cities {

			.main-title {
				width: 60%;
			}

		}

	}

	// Tablet
	// ------------------------------------------------------------------------------------
	@include breakpoint(tablet) {

		.block-search {

			.form {
				padding: 1rem 2rem;
			}
		}

		.block-cities {

			.main-title {
				width: 50%;
			}

			.cities-list {

				padding: 0 ($grid-gutter-width * 2);

				.item {
					&:nth-child(3n+1) {
						clear: left;
					}
				}

			}

		}

	} // tablet end

	// desktop
	// ------------------------------------------------------------------------------------
	@include breakpoint(desktop) {

		.block-search {

			> .inner {
				width: 85%;
			}

		}

	} // desktop end

}


// Reinit search link style on all pages
.reinit_search {
	clear: both;
	text-align: right;
	padding-top: 1rem;

	a {
		font-weight: bold;
		text-decoration: underline;

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}
}
