// --------------------------------------------------------------------------------------------
// RESIDENCE
// --------------------------------------------------------------------------------------------

body.residence,
body.search-city,
body.residence-carte {

	.template {
		position: relative;
	}

	#CarteController {
		position: relative;
		.angular-leaflet-map {
			width: 100%;
			height: $height-map;
		}
	}

	// Search
	// -----------------------------------------------------------------------------------
	.block-search {

		margin-bottom: $grid-gutter-width;

		.alert-search {
			margin: 0 0 .5em;
			padding: .5em;
			position: relative;
			top: -.2em;
		}

		> .inner {
			@include tight-row;
			// Background-image is dynamically load in inline style in the view
			background: none no-repeat center;
			background-size: auto 100%;
			min-height: $residence-search-height;
		}

		.wrapper {

			background: rgba(white, .9);
			padding: 1em 1.5em 0.5em 1.5em;
			margin: 1em 0;

	//		fieldset > legend,
	//		.dates-group legend,
	//		.localisation label {
	//			@extend .sr-only;
	//		}

			div.localisation .control-label {
				@extend %legend_label;
			}

		}

		@include breakpoint(tablet) {

			.wrapper {
				margin-top: 3.3em;
			}

			.line {
				&.line-2 {
					margin-bottom: 0;
				}
			}

			.radios {
				.radio {
					margin: 0;
				}
			}

		}

	} // block-search end

	// Filtres
	// -----------------------------------------------------------------------------------

	.block-filters {

		padding: 0 0 $grid-gutter-width;

		.filters-list {

			text-align: right;

			.label {
				@extend .btn;
				@extend .btn-link;
				color: $color3;
			}

			.item {

				> a {

					color: $color3;

					.sort {
						display: inline-block;
						vertical-align: middle;
						background: url(#{$img-path}icon-arrow-01-down.png) no-repeat center;
						width: 16px;
						height: 16px;
						margin: 0 0 0 .3em;
					}

				}

				&.desc {

					> a {
						.sort {
							background-image: url(#{$img-path}icon-arrow-01-up.png);
						}
					}

				}

			}

			&.hidden-xs {

				.menu, .item {

					display: inline;

				}

			}

		}

	} // block-filters end

	// Titre résultats de recherche
	// -----------------------------------------------------------------------------------

	.results-title {

		@include group;
		margin: 0 0 $grid-gutter-width;
		border-bottom: 2px solid $color2;

		.btn-to-map {
			@extend .pull-right;
		}

		.title {
			@include font-size(21);
			@extend .pull-left;
			font-family: $font1;
			color: $color3;
			line-height: 1em;
			margin: .3em 0 0;
		}

		@include breakpoint(tablet) {

			.title {
				@include font-size(28);
			}

		}

	}

	// Liste des résidences (résultats de recherche)
	// -----------------------------------------------------------------------------------
	.search-results {

		$nb-search-result-per-line: 2;

		.item {

			padding-bottom: ($grid-gutter-width / 2);

			&:nth-child(#{$nb-search-result-per-line}n+1) {
				clear: left;
			}

			> .inner {
				@include transition;
				background: white;
				border-radius: $border-radius-extra;
				overflow: hidden;
			}

			@include hover {
				> .inner {
					box-shadow: $box-shadow-base-hover;
				}
			}

			.header,
			.footer {
				padding: ($grid-gutter-width / 2);
			}

			.header {
				@extend %header_residence;
			}

			.carousel {

				.slides {
					background: darken($center-bg, 3%);
				}

				.thumbs {
					padding: ($grid-gutter-width / 2);
				}

			}

			.footer {

				.map-infos {

					padding: 0 0 ($grid-gutter-width / 2);

					> li {

						> a {
							@include font-size(14);
							line-height: 1.2em;
							color: $color3;
							@include hover {
								text-decoration: underline;
							}
						}

						&.to-map {
							> a {
								@include icon('map');
								background-size: auto 100%;
								padding: 0 0 0 20px;
							}
						}

						&.from-city {
							> span {
								@include icon('target');
								background-size: auto 100%;
								padding: 0 0 0 20px;
							}
						}

					}

				}

				.rooms-type {

					> li {
						@include font-size(14);
						line-height: 1.2em;
						font-weight: bold;
						color: $color3;
					}

				}

				.price {
					@extend %price;
				}

				.price-info {
					@extend %price-info;
				}

				.action {

					padding: ($grid-gutter-width / 2) 0 0;

				}

			}

		}

		@include breakpoint(tablet) {

			.item {

				.carousel {

					.slide {
						img {
							max-height: 202px;
						}
					}

				}

			}

		}

	} // search-results end


	// Liste des résidences (résultats de recherche en mode carte)
	// -----------------------------------------------------------------------------------
	#CarteController {
		position: relative;
		z-index: 5;

		// styles des icones de la carte
		.leaflet-marker-icon {
			text-align: center;
			@include font-size(22);
			font-family: $font1;
			display: block;
			padding-top: 8px;
			// icone de regroupement
			&.marker-cluster {
				background: rgba(grayscale($color2-alt2), 0.6);
				border: 1px solid #fff;
				color: #fff;
				border-radius: 20px;
				padding: 0;
				line-height: 1.8em;
				display: inline;
			}
			&.nearResidences-icon {
				background: url(/bundles/app/images/map/icon_nearResidences.png) no-repeat 0 0;
				color: grayscale($color2-alt2);
			}
			&.residences-icon {
				background: url(/bundles/app/images/map/icon_residences.png) no-repeat 0 0;
				color: $color2-alt2;
			}
		}

		.residences-liste {
			padding: 0;
			z-index: 5;
			background: white;

			@include breakpoint(tablet) {
				position: absolute;
				left: 0;
				top: 75px;
				border-radius: $border-radius-extra;
				box-shadow: $box-shadow-base-hover;
				width: 33%;
			}

			> .inner {
				overflow: auto;
				max-height: $height-map - 150;
				margin: 10px;
    			padding-right: 10px;

				article {
					@include group;
					border-bottom: 1px solid lighten($color2-alt2, 45%);
					padding-bottom: 10px;
					margin-bottom: 10px;
					.header {
						@extend %header_residence;
						.name {
							padding-bottom: 0;
						}
						.index-result {
							float: left;
							background: $color2-alt2;
							color: #fff;
							font-family: $font1;
							padding: 3px 9px;
							display: inline-block;
							@include font-size(24);
							margin-right: 10px;
							border-bottom-right-radius: $border-radius-extra;
							border-bottom-left-radius: $border-radius-extra;
						}
					}
					.row {
						margin: 10px 0 0 0;
						.price {
							@extend %price;
							margin-left: 15px;
						}
						.price-info {
							@extend %price-info;
							@include font-size(11);
							margin-left: 15px;
						}
						.action {
							margin-left: 15px;
							margin-top: 15px;
						}
						.colonne {
							padding: 0;
							img {
								max-width: 100%;
							}
						}
					}
				}
			}

		} // residences-liste end

		.angular-leaflet-map {
			h2,
			p.address {
				font-family: $font-family-sans-serif;
				font-weight: bold;
				@include font-size(14);
				margin: 0;
				text-align: center;
			}
			.price {
				@extend %price;
				padding-top: .2em;
				padding-bottom: 0;
				text-align: center;
			}
			.price-info {
				padding-bottom: .2em;
				@extend %price-info;
				@include font-size(12);
				text-align: center;
			}
			.action a.btn {
				color: #fff;
    			padding: 1px 12px;
			}
		} // angular-leaflet-map end
	}

	// Navigation transversale
	// -----------------------------------------------------------------------------------

	.cross-nav {

		margin: 0 0 $grid-gutter-width;
		@include tight-row;

		ul {
			@include group;
		}

		.item {

			display: block;

			> a {

				@include font-size(14);
				display: block;
				color: $gray-dark;
				font-weight: bold;
				line-height: .8rem;
				text-decoration: underline;

				@include hover {
					text-decoration: none;
				}

			}

			&.previous {

				float: left;

				> a {
					> span {
						background: url(#{$img-path}icon-arrow-01-left.png) no-repeat 0 center;
						padding: 0 0 0 20px;
					}
				}

			}

			&.next {

				margin-top: 1.5rem;
				text-align: right;

				float: right;
				margin-top: 0;
				> a {
					> span {
						background: url(#{$img-path}icon-arrow-01-right.png) no-repeat right center;
						padding: 0 20px 0 0;
					}
				}

			}

		}

	}

	// Main details
	// -----------------------------------------------------------------------------------

	.block-main-details {

		@include tight-row;
		margin: 0 0 $grid-gutter-width;

		> .inner {

			@include make-row(0);
			border-radius: $border-radius-extra;
			background: $color6;
			box-shadow: $box-shadow-base;
			overflow: hidden;

		}

		.column-info {

			> .inner {
				padding: ($grid-gutter-width / 2);
			}

			&.info-1 {
				@include make-sm-column(8,0);
				background: white;
			}

			&.info-2 {
				@include make-sm-column(4,0);
			}

		}

		.main-title {
			padding-bottom: .2em;
		}

		.capacity {
			@include font-size(14);
			font-family: $font0;
			font-weight: bold;
			color: $color4;
			margin: 0 0 1.5em;
		}

		.carousel {
			margin: 0 0 $grid-gutter-width;
		}

		.additional-services {

			@include make-row;

			@include breakpoint(tablet) {
				margin-bottom: $grid-gutter-width;
			}

			.service {

				@include make-md-column(6);
				padding-bottom: $grid-gutter-width;

				&:nth-child(2n+1) {
					clear: left;
				}

				.inner {
					padding: 0 0 0 36px;
				}

				.aide {
					@extend %aide;
				}

				.wrapper {
					border-left: 1px solid $color2;
					padding: 0 0 0 .7rem;
				}

				.title {
					@include font-size(14);
					font-family: $font-family-sans-serif;
					font-weight: bold;
					color: $gray-dark;
					margin: 0 0 .5em;
				}

				.listing {
					display: block;
					padding: 0;
					margin: 0;
					.item {
						display: block;
						padding: 0;
						margin: 0;
						&:before {
							content: '-';
							display: inline-block;
							vertical-align: middle;
							margin: 0 .2em 0 0;
						}
					}
				}

				&.common-space,
				&.espaces-co {
					> .inner {
						@include icon ( "laundromat", $type: 2, $bg-position: 0 0 );
					}
				}

				&.common-equipment,
				&.equipements-co {
					> .inner {
						@include icon ( "accessibility", $type: 2, $bg-position: 0 0 );
					}
				}

				&.proximity,
				&.a-proximite {
					> .inner {
						@include icon ( "proximity", $type: 2, $bg-position: 0 0 );
					}
				}

				&.misc {
					> .inner {
						@include icon ( "food", $type: 2, $bg-position: 0 0 );
					}
				}

				@include breakpoint(mobileonly) {
					&:last-child {
						padding-bottom: 0;
					}
				}

			}

		}

		.informations {

			.info {

				padding: 0 0 1.5em;

				.title {
					@include font-size(16);
					font-family: $font-family-sans-serif;
					font-weight: bold;
					color: $color0;
					margin: 0 0 .5em;
				}

				p {
					line-height: 1.3em;
					.name {
						font-weight: bold;
					}
				}

				.link {
					color: $gray-dark;
					text-decoration: underline;
					@include hover {
						text-decoration: none;
					}
				}

			}

		}

	} // block-main-details end



	// Map of residence (for print)
	// -----------------------------------------------------------------------------------

	.carte-residence-print {
		position: absolute;
		top: -999em;
		width: 1024px;
		height: 800px
	}

	// Listing logements
	// -----------------------------------------------------------------------------------

	.block-rooms {

		@include tight-row;

		.room {

			border-radius: $border-radius-extra;
			background: white;
			box-shadow: $box-shadow-base;
			overflow: hidden;
			margin: 0 0 ($grid-gutter-width / 2);

			.part {
				&.part-1 {
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					justify-content: flex-start;
					align-content: stretch;
					> div {
						order: 0;
						flex: 0 1 auto;
						align-self: auto;
						&.column-quantity {
							position: relative;
							.inner{
								.btn-submit, .column-unavailable {
									display: block;
									position: absolute;
									top: 50%;
									margin-top: -18px;
									margin-left: -15px;
									width: 85%;
								}
							}
						}
					}
				}

				&.part-2 {
					//background: $color6;
				}
			}

			.column {
				> .inner {
					padding: .8rem;
				}

				&.column-type,
				&.column-resume {
					background: white;
				}

				&.column-price,
				&.column-quantity,
				//&.column-gallery,
				&.column-details {
					background: $color6;
				}

			}

			.type {

				@include font-size(20);
				text-transform: uppercase;
				color: $color1-alt;
				margin: .7rem 0 0;

				.button {

					border: 0;
					padding: 0;

					.in {
						background: url(#{$img-path}icon-arrow-01-down.png) no-repeat right center;
						padding-right: 22px;
					}

					&[aria-expanded=true] {
						.in {
							background-image: url(#{$img-path}icon-arrow-01-up.png);
						}
					}

				}

			}

			.resume {

				> .item {

					@include font-size(14);
					display: block;
					color: $gray-dark;
					background: none no-repeat 0 center;
					background-size: auto 85%;
					line-height: 1.6em;
					padding: 0 0 .2em 25px;
					white-space: nowrap;

					&:last-child {
						padding-bottom: 0;
					}

					&.peoples {
						@include icon($name: user, $bg-image: true);
					}

					&.beds {
						@include icon($name: simplebed, $bg-image: true);
					}

					&.sanitary {
						@include icon($name: bath, $bg-image: true);
					}

					&.litSupplementaire {
    					white-space: inherit;
						@include font-size(10);
					}

				}

			}

			.price {
				@include font-size(40);
				font-family: $font1;
				color: $color0;
				line-height: 1em;
				padding: 0 0 .4rem;
			}

			.price-info {
				@include font-size(13);
				color: $gray-dark;
				line-height: 1em;
			}

			.column-quantity {

				text-align: center;

				.label {
					@include font-size(14);
					color: $gray-dark;
					font-weight: bold;
					line-height: 1em;
					padding: 0 0 .6rem;
				}
				a.btn,
				.spin-control {
					//@extend %spin-control;
					width: 100%;
				}

				// je suis pas hyper emballé pour masquer cette section
				// mais bon : https://redmine.vtech.fr/issues/60712#note-6
				@media print {
					display: none;
				}

			}

			.details {

				> .item {

					@include make-row;
					padding: .6rem 0;

					&:not(:last-child) {
						border-bottom: 1px solid white;
					}

					.key {
						@include make-xs-column(6);
						@include font-size(14);
						text-align: right;
						color: $color0;
						font-weight: bold;
						line-height: 1.3em;
					}

					.value {
						@include make-xs-column(6);
						@include font-size(14);
						line-height: 1.3em;
					}

					.aide {
						@extend %aide;
						top: 0;
					}

				}

			}

		}

		// Tablet
		// -----------------------------------------------------------------------------
		@include breakpoint(tablet) {

			.room {

				.type {
					@include font-size(25);
				}

				.column {
					&.column-quantity,
					&.column-gallery {
						background: white;
					}
				}

				.price,
				.price-info {
					text-align: right;
				}

				.column-quantity {

					.spin-control {
						width: 70%;
					}

				}

			}

		} // tablet end

		@include breakpoint(mobile-portrait) {
			.room {
				.part {
					&.part-1 {
						flex-direction: column;
					}
				}
			}
		}

	} // block-rooms end

	// Long stay
	// -----------------------------------------------------------------------------------

	.block-long-stay {

		margin: 0 0 $grid-gutter-width;
		@include tight-row;

		border-radius: $border-radius-extra;
		background: white;
		box-shadow: $box-shadow-base;
		overflow: hidden;
		margin: 0 0 ($grid-gutter-width / 2);

		.row{
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-content: stretch;
			> div {
				order: 0;
				flex: 0 1 auto;
				align-self: auto;
				&.column-contact {
					text-align: center;
					position: relative;
					.btn {
						display: block;
						position: absolute;
						top: 50%;
						margin-top: -18px;
						margin-left: -15px;
						width: 85%;
					}
				}
			}
		}

		.column {

			> .inner {
				padding: .8rem;
			}

			&.column-contact,
			&.column-message {
				background: white;
			}

			&.column-price {
				background: $color6;
			}

		}

		.price {
			@include font-size(40);
			font-family: $font1;
			color: $color0;
			line-height: 1em;
			padding: 0 0 .4rem;
			text-align: right;
		}

		.price-info {
			@include font-size(13);
			color: $gray-dark;
			line-height: 1em;
			text-align: right;
		}

		.column-contact {

			text-align: center;

		}
	} // long-stay end


	// Barre de réservation
	// -----------------------------------------------------------------------------------
	.booking-bar {

		position: fixed;
		width: 100%;
		bottom: 0;
		left: 0;
		z-index: 1000;
		background: $booking-bar-bg;


		@media print {
			background: transparent;
			position: relative
		}

		.container {
			position: relative;
		}

		#alertLimitLogements {
			position: absolute;
			background: $color1-alt;
			font-family: $font1;
			border: 0;
			box-shadow: 0;
			@include font-size(20);
			color: #fff;
			padding: 10px;
			text-align: left;
			bottom: 100%;
			left: 0;
			right: 0;
			margin-left: 0;
			margin: 0;
			@media print {
				color: $color1-alt;
			}
		}

		&, a { 
			color: white;
			@media print {
				color: $booking-bar-bg;
			}
		}

		.column {

			text-align: center;

			> .inner {
				padding: .6rem 0;
			}

			&.mentions {

				background: darken($booking-bar-bg, 7%);
				line-height: 1.4em;

				> .inner {
					@include icon ( "key", $type: 3, $bg-position: 0 center );
					padding: .6rem 0 .6rem 36px;
				}

				.line {
					&.line-1 {
						@include font-size(17);
						font-family: $font1;
						margin: 0 .5rem 0 0;
					}
					&.line-2 {
						@include font-size(14);
						text-decoration: underline;
						font-weight: bold;
					}
				}

			}

		}

		.selection {
			@include font-size(21);
			display: inline-block;
			vertical-align: middle;
			font-family: $font1;
			text-transform: uppercase;
			margin-right: .7em;
		}

		.btn-book {
			@include font-size(28);
			line-height: 1em;
			@media print {
				color: $brand-primary;
			}
		}

		// Tablet
		// -----------------------------------------------------------------------------
		@include breakpoint(tablet) {

			.column {

				&.booking {
					float: right;
					text-align: right;
				}

				&.mentions {

					text-align: left;
					background: $booking-bar-bg;

					.line {
						&.line-1 {
							@include font-size(20);
							display: block;
						}
					}

				}

			}

			.selection {
				margin-right: .8rem;
			}

		} // tablet end

	}

} // body.residence end
