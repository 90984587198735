/**
 *
 */

%block {
	margin: 0 0 $grid-gutter-width;
}

%block-header {
	background: white;
	border-radius: $border-radius-extra $border-radius-extra 0 0;
}

%block-title {
	@include font-size(30);
	font-family: $font1;
	color: $color3;
	padding: ($grid-gutter-width / 2) ($grid-gutter-width / 1.5);
	line-height: 1em;
}

%block-body {
	padding: ($grid-gutter-width / 1.5);
	background: lighten($center-bg, 2%);
	border-radius: 0 0 $border-radius-extra $border-radius-extra;
}
