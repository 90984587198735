// --------------------------------------------------------------------------------------------
// APP STYLES
// --------------------------------------------------------------------------------------------

// Main
// ----------------------------------------------------------------------------

.block-main {

	padding: 0 0 $grid-gutter-width 0;
	background: $center-bg;

}
