// --------------------------------------------------------------------------------------------
// TITLES
// --------------------------------------------------------------------------------------------

// For all H titles
@for $i from 1 through 6 {

	h#{$i},
	.title-level-#{$i} {

		margin: 0;
		font-family: $font1;
		font-weight: normal;
		line-height: 1.1em;

	}

}

h2,
.title-level-2 {

	@include font-size(24);
	padding: 0 0 .7em;

	&, a {
		color: $color1-alt;
	}

}



h3,
.title-level-3 {

	@include font-size(20);
	padding: 0 0 .5em;

	&, a {
		color: $color0;
	}

}


h4,
.title-level-4 {

	@include font-size(18);
	padding: 0 0 .3em;

	&, a {
		color: $color2;
	}

}