// --------------------------------------------------------------------------------------------
// CAROUSEL
// --------------------------------------------------------------------------------------------

// http://www.w3.org/WAI/tutorials/carousels/animations/
// http://csswizardry.com/2011/10/fully-fluid-responsive-css-carousel/
// http://cssslider.com/responsive-slider-2.html

.carousel {

	position: relative;

	img {
		@include img-responsive;
	}

	.slides {

		@include transition;
		@include group;
		position: relative;
		width: 100%;
		background: $carousel-bg;

	}

	.viewport {
		position: relative;
		overflow: hidden;
	}

	.slide {

		@include transition;
		display: none;
		position: relative;
		width: 100%;
		float: left;

		&.current {
			display: block;
		}

	}

	.controls {

		//@extend .sr-only;
		//@extend .sr-only-focusable;

		.control {

			display: block;
			position: absolute;
			width: 10%;
			height: 100%;
			top: 0;

			.button {

				@include transition;
				@include font-size(24);
				position: absolute;
				width: 100%;
				height: 100%;
				color: $color2-alt;
				padding: 0 .3rem;
				background: rgba(white,.3);

				&:hover {
					background-color: rgba(white,.5);
				}

			}

			&.previous {
				left: 0;
			}

			&.next {
				right: 0;
			}

		}

		@include breakpoint(tablet) {

			.control {
				width: 6%;
				.button {
					background: none;
					&:hover {
						background-color: rgba(white,.5);
					}
				}
			}

		}

	}

	.thumbs {

		@include make-row($carousel-thumb-spacing);
		padding-top: $carousel-thumb-spacing;

		.thumb {

			@include make-xs-column( 2, $carousel-thumb-spacing );
			padding-bottom: $carousel-thumb-spacing;

			&:nth-child(6n+1) {
				clear: left;
			}

			.button {

				display: block;
				position: relative;
				white-space: normal;
				width: 100%;
				padding: 0;
				overflow: hidden;

				&:after {
					@include transition;
					content: '';
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					background: rgba($color0-alt, .5);
				}

				&.current,
				&:hover {
					&:after {
						left: -100%;
					}
				}

			}

		}

	}

	@for $i from 2 through 12 {

		&.has-#{$i}-slides {

			.slides {
				width: ( 100% * $i );
			}

			.slide {
				width: ( 100% / $i );
			}

		}

	}

	&.has-1-slide {

		.slide {
			display: block;
		}

	}

} // carousel end