// --------------------------------------------------------------------------------------------
// CHECKOUT (Tunnel resa)
// --------------------------------------------------------------------------------------------

// Steps
// -----------------------------------------------------------------------------------
.block-steps {

	@include group;
	@include tight-row;
	margin: $grid-gutter-width 0;

	> .item {

		display: block;
		float: left;
		width: $checkout-step-width;

		> .link {

			@include group;
			@include font-size(22);
			display: block;
			line-height: 1rem;
			font-family: $font1;
			text-transform: uppercase;
			color: $checkout-step-color;
			background: $checkout-step-bg;

			@media print {
				color: $checkout-step-bg;
			}

			.num,
			.name {
				float: left;
				padding: $checkout-step-padding;
			}

			.num {
				width: 100%;
				background: lighten($checkout-step-bg, 5%);
				text-align: center;

				@media print {
					color: lighten($checkout-step-bg, 5%);
				}

			}

			.name {
				display: none;
			}

		}

		&.item-2,
		&.item-3 {
			> .link {
				border-left: 3px solid $center-bg;
			}
		}

		&.active {

			width: $checkout-step-width-active;

			> .link {

				background: $checkout-step-bg-active;

				.num {
					width: $checkout-step-num-width;
					background: lighten($checkout-step-bg-active, 5%);
				}

				.name {
					display: block;
				}

			}

		}

	}

	// Tablet
	// -----------------------------------------------------------------------
	@include breakpoint(tablet) {

		> .item {

			&,
			&.active {
				width: ( 100% / $checkout-steps );
			}

			> .link {

				.num {
					width: $checkout-step-num-width;
				}

				.name {
					display: block;
				}

			}

			&.item-2,
			&.item-3 {
				> .link {
					border: 0;
				}
			}

		}

	} // Tablet end

} // block-steps end

// Details and summary
// -----------------------------------------------------------------------------------
.block-details {
	@extend %block;

	#yoocitiz_paiement_type {
		position: relative;
		margin: 20px auto;
		legend {
			@include font-size(14);
			color: $color3;
		}
		.radio_elements {
			display: table;
			margin:auto;
			.radio_element {
				display: table-cell;
				padding: 10px;
				text-align: center;
				width: 51px;
				height: 32px;
				label {
					cursor: pointer;
					display: block;
					position: relative;
					z-index: 9;
				}
			}
		}
	}

	.header {
		@extend %block-header;

		.title {
			@extend %block-title;
			.tab-content & {
				padding-left: 0;
			}
		}

	}

	img.image_residence {
		max-width: 100%
	}

	.wrapper {
			@extend %block-body;
	}

	table.checkout {
		width: 100%;
  		border-collapse: collapse;
		th {
			@extend %legend_label;
			display: table-cell;
			vertical-align: top;
			position: absolute;
			top: -9999px;
			left: -9999px;
			@include breakpoint(tablet) {
				position: relative;
				top: 0;
				left: 0;
			}
			@media print {
				position: relative;
				top: 0;
				left: 0;
			}
		}
		tr {
			background: #fff;
			&:nth-of-type(odd) {
				background: #ccc;
			}
			@include breakpoint(tablet) {
				background: #fff;
				&:nth-of-type(odd) {
					background: #fff;
				}
			}
			&.prix {
				background: #f0f0f0;
				&.restant_du,
				&.frais_reservation {
					color: $color2;
				}
				&.frais_reservation_paye {
					color: $color0;
				}
				@include breakpoint(tablet) {
					td[colspan]{
						text-align: right;
					}
				}
				@media print {
					td[colspan]{
						text-align: right;
					}
				}
				td:before {
					// on affiche pas d'entete 'RWD' pour les lignes de prix
					display: none !important;
				}
			}
			td {
				/* Behave  like a "row" */
				border: none;
				border-bottom: 1px solid #eee;
				position: relative;
				padding: 2px;
				padding-left: 50%;
				display: block;
				position: relative;
				min-height: 2em;
				@include font-size(12);
				@include breakpoint(tablet) {
					font-size: inherit;
					padding: 12px;
					display: table-cell;
					border: 0;
					border-right: 2px solid #f0f0f0;
				}
				@media print {
					font-size: inherit;
					padding: 12px;
					display: table-cell;
					border: 0;
					border-right: 2px solid #f0f0f0;
				}
				&.total_net,
				&.prix {
					font-weight: bold;
				}
				.spin-control {
					min-width: 100px;
				}
				span.table-header {
					/* Now like a table header */
					position: absolute;
					/* Top/left values mimic padding */
					top: 6px;
					left: 6px;
					width: 45%;
					padding-right: 10px;
					@extend %legend_label;
					@include font-size(12);
					white-space: nowrap;
					@include breakpoint(tablet) {
						display: none;
					}
					@media print {
						display: none;
					}
				}
			}
			td:last-child {
				border-right: 1px solid transparent;
			}
		}
	}

	@include breakpoint(mobileonly) {
		table.checkout {
			max-width: 15rem;
			width: 15rem;
			margin: 0 auto;
		}
	}

	@include breakpoint(tabletonly) {
		table.checkout {
			max-width: 35rem;
			width: 35rem;
			margin: 0 auto;
		}
	}

	@media print {
		table.checkout {
			max-width: 100%;
			width: 100%;
		}
	}

	// Titles styles
	%recap-title-1 {
		@include font-size(22);
		font-family: $font1;
		color: $color1-alt;
		padding: 0 0 .7rem;
		line-height: 1em;
	}
	%recap-title-2 {
		@include font-size(17);
		color: $color0;
		font-weight: bold;
		padding: 0 0 .7rem;
		line-height: 1em;
	}
	%recap-title-3 {
		@include font-size(14);
		color: $color3;
		font-weight: bold;
		padding: 0 0 .7rem;
		line-height: 1em;
	}

	p.labelEquipementsSupplements {
		font-weight: bold;
		padding-bottom: 0;
	}
	ul.equipementsSupplements {
		li {
			list-style: disc;
			list-style-position: inside;
		}
	}

	.block {

		padding: 0 0 ($grid-gutter-width / 2);
		margin: 0 0 ($grid-gutter-width / 2);
		border-bottom: 1px solid #f0f0f0;

		&:last-child {
			padding-bottom: 0;
			margin-bottom: 0;
			border-bottom: 0;
		}

		p {

			@include font-size(14);
			line-height: 1.5em;
			color: $color3;

			.label {
				font-weight: bold;
			}

		}

		&.residence {

			.title {
				@extend %recap-title-1;
			}

			.address {
				padding: 0;
			}

		}

		&.rooms {

			.room {
				list-style: none;
			}

		}

		&.discount-code {

			.title {
				@include font-size(16);
				font-family: $font-family-base;
				font-weight: bold;
				color: $color3;
			}

			@include breakpoint(mobileonly) {
				input[type=text] {
					margin-bottom: .7rem;
				}
			}

		}

		&.additional-infos {
			h3,
			h4 {
				@extend %recap-title-2;
				font-family: $font-family-sans-serif;
				@include font-size(16);
			}

			p {
				&.contact {
					font-style: italic;
				}
				&.conditions_annulation {
					font-weight: bold;
				}
				a {
					color: $color4;
					text-decoration: underline;
					@include hover {
						text-decoration: none;
					}
				}

				&.address {
					padding-bottom: 0;
					.label {
						float: left;
						display: block;
						margin-right: .25em;
					}
					.value {
						overflow: auto;
						display: block;
					}
				}
			}


		}

		&.total-amount {

			@include font-size(17);
			@include group;
			line-height: 1em;
			background: #f0f0f0;
			color: $color2;
			border: 0;
			border-radius: $border-radius-extra;
			padding: ($grid-gutter-width / 2);
			overflow: auto;
			zoom: 1;

			&.frais-reservation {
				background: $color2;
				color: white;
				@media print {
					color: $color2;					
				}
			}

			.label {
				float: left;
				width: 65%;
				font-weight: normal;
			}

			.value {
				float: right;
				width: 35%;
				text-align: right;
				font-weight: bold;
			}

		}

		&.bills {

			.title {
				@extend %recap-title-2;
			}

		}

		&.empty-cart {

			.link {

				@include font-size(14);
				display: inline-block;
				color: $color4;
				font-style: italic;

				@include hover {
					text-decoration: underline;
				}

			}

		}

		&.continue {
			text-align: right;
			.btn {
				@include font-size(26);
			}
		}

	} // block end

	&.summary {

		@include make-md-column(4);
		@include tight-row(true);

	} // summary end

	&.informations {

		@include make-md-column(8);
		@include tight-row(true);

		.form {

			.title {

				@include font-size(22);
				font-family: $font1;
				color: $color1-alt;
				padding: 0 0 1rem;
				line-height: 1em;

				label {
					font-weight: normal;
				}

			}

			.reservation {

				padding: 0 0 $grid-gutter-width;
				margin: 0 0 $grid-gutter-width;
				border-bottom: 1px solid white;

			}

			#alertLimitLogements,
			.intro {

				@include font-size(16);
				color: $color3;
				font-weight: bold;
				font-style: italic;
				padding: 0 0 1rem;
				line-height: 1em;

				a {
					color: $color3;
					text-decoration: underline;
				}

			}

			.label {
				@extend %legend_label;
			}
			div.checkbox,
			div.radio {
				.label {
					@extend %label_checkbox;
				}
			}

			.radio-inline {
				@include font-size(14);
				font-weight: bold;
			}

			.list {

				.item {

					&:not(:last-child) {
						margin-bottom: .6rem;
					}

					.name {

						@include font-size(14);

						label {

							display: block;
							font-weight: normal;

							@include hover {
								text-decoration: underline;
							}

						}

					}

					.selection {

						text-align: center;

						.control-label {
							&, + span {
								@extend .sr-only;
								@extend .sr-only-focusable;
							}
						}

						.spin-control {
							width: 100%;
						}

					}

					.price {
						@include font-size(14);
						span {
							font-weight: bold;
						}
					}

					&.total {

						.name {
							font-weight: bold;
						}

						.price {
							@include font-size(17);
							font-weight: bold;
						}

					}

				}

			}

			.conditions {

				margin-top: 1.5rem;

				p, a {
					color: $color3;
				}

				a {
					font-weight: bold;
					text-decoration: underline;
					@include hover {
						text-decoration: none;
					}
				}

			}

			.validate {

				margin-top: 1.5rem;

				.btn {
					@include font-size(24);
				}

			}

		} // form end

	} // informations end

	&.opening-hours {

		.block {

			.title {
				@extend %recap-title-3;
			}

		}

	}

	// Tablet
	// -----------------------------------------------------------------------
	@include breakpoint(tablet) {

		&.summary {

			float: right;

		}

		&.informations {

			@include make-sm-column(8);
			@include tight-row(true);

			.form {

				.list {

					.item {

						border: 0;

						.price {
							text-align: left;
						}

					}

				}

			}

		}

	}

}

// Finalize
// -----------------------------------------------------------------------------------
body.finalize {

	.block-details {

		.block {

			&.discount-code {
				display: none;
			}

		}

	}
}
