#bandeau_cnil {
	position: static;
	background: $color4;
	padding: .5em;
	display: none;
	@include font-size(14);
	p  {
		padding: 0;
		color: white;
	}
	a {
		color: $color6;
		text-decoration: underline;
		@include hover {
			text-decoration: none;
		}
	}
}