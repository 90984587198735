/**
 *
 */

.support-title {
	@extend %block-title;
	text-align: center;
}

.support-description {
	margin-bottom: 2em;
}

.support-block {
	@extend %block;
}

.support-block-header {
	@extend %block-header;
}

.support-block-title {
	@extend %block-title;
}

.support-block-body {
	@extend %block-body;

	.panel.panel-default {
		margin-bottom: 3%;

		.well {
			padding: 2%;
			margin-top: 2%;
			margin-bottom: 1%;
			border-radius: 4px;
			box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
			background-color: $header-bg;
		}
	}

	.attachment-link {
		padding-right: 1%;
		font-weight: bold;
		text-decoration: underline;
	}
}

.support-block-body .label {
	@extend %legend_label;
}

.support-block-body .checkbox .label,
.support-block-body .radio .label {
	@extend %label_checkbox;
}

.support-block-body fieldset {
	margin-bottom: 15px;
}

.support-block-body fieldset label {
	padding-right: 20px;
	padding-left: 5px;
}
