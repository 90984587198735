// --------------------------------------------------------------------------------------------
// FOOTER
// --------------------------------------------------------------------------------------------

.block-footer {

	.inner {

		background: $color4;
		padding: .7em 0;

		.has-booking-bar & {
			padding-bottom: 6rem;
		}

	}

	&, a { color: white; }

	.block-logo {

		img {
			max-height: 55px;
		}

	}

	.block-menu {

		text-align: center;
		padding-top: 1em;

		li {

			display: block;
			border-top: 1px solid darken($color4, 7%);

			> a {
				@include font-size(15);
				display: block;
				padding: .6em;
			}
		}

	}

	// Tablet
	// ----------------------------------------------------------------
	@include breakpoint(tablet) {

		.block-menu {

			text-align: right;
			padding-top: 0;

			li {

				display: inline-block;
				vertical-align: middle;
				border: 0;

				> a {
					display: inline;
					padding: 0;
				}

				& + li {

					&:before {
						content: ' - ';
						display: inline-block;
						vertical-align: middle;
						margin-right: .3em;
					}

				}

			}
		}

	}

}