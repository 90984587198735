section#contact {

	@include tight-row;
	margin: $grid-gutter-width 0 0 0;

	> .inner {

		@include make-row(0);
		border-radius: $border-radius-extra;
		background: $color6;
		box-shadow: $box-shadow-base;
		overflow: hidden;
		padding: ($grid-gutter-width / 2);

		form {
			div.row > fieldset,
			div.row > div {
				margin-top: 10px;
				margin-bottom: 10px;
				border: 0;
				legend,
				label {
					@include font-size(16);
					color: $color0;
					border: 0;
					font-weight: bold;
					padding: 0;
					margin: 0;
					// Tout les champs sont obligatoires, je masque l'étoile
					span {
						@extend .sr-only;
					}
				}
			}
			fieldset #contact_role {
				> div {
					display: inline-block;
				}
				label {
					color: #333;
					margin-right: 20px;
					margin-left: 5px;
					@include font-size(14);
				}
			}
		}

	}

}
