// --------------------------------------------------------------------------------------------
// HEADER
// --------------------------------------------------------------------------------------------

.block-header {

	background: white;

	.inner {
		background: white;
		padding: .5em 0;
		// sur la homepage, le header est plus gros
		.homepage & {
			padding: 1em 0 1.5em;
		}
	}


	.block-logo {
		padding: 0;

		a {
			display: inline-block;
			vertical-align: middle;
			margin: 0 1em;
			img {
				@include img-responsive(80%);
				margin: 0;
				// sur la homepage, le logo est plus gros
				.homepage & {
					@include img-responsive;
				}
			}
		}

		p {
			display: inline-block;
			vertical-align: middle;

			.baseline-bold {
				@include font-size(24);
				font-family: $font2;
				color: $color8;
				line-height: 1em;
			}

			.baseline-regular {
				@include font-size(24);
				font-family: $font3;
				color: $color3;
				line-height: 1em;
			}
		}

	}

	.block-menu-lg {

		padding-top: 1rem;

		.homepage & {
			padding-top: 1.5rem;
		}

		.menu {

			text-align: right;
			white-space: normal;

			> .item {

				display: inline-block;
				vertical-align: middle;
				padding: .2em .7em;

				position: relative;

				+ .item {
					border-left: 1px solid rgba(0,0,0,.2);
				}

				> a,
				> button {

					@include font-size(14);
					font-family: $font0;
					color: $color2;
					display: inline-block;
					width: $icon-mid-width;
					height: $icon-mid-height;
					line-height: $icon-mid-height;

					&.btn-logout {
						width: auto;
						vertical-align: top;
						background: none !important;
					}

					> span {
						@extend .sr-only;
					}

				}

				&.open {
					background-color: $color5;
				}

				&.langs {
					> button {
						@include icon ( "lang", $type: 1, $bg-position: 0 center );
					}
				}

				&.favs {
					> a {
						@include icon ( "heart", $type: 1, $bg-position: 0 center );
					}
				}

				&.account {
					> a {
						@include icon ( "user", $type: 1, $bg-position: 0 center );
					}
				}

			}

		}

		.dropdown-menu {
			left: auto;
			right: 0;
		}

	}

	.block-menu-xs {

		text-align: right;

		.navbar-toggle {

			background: white;
			margin-right: 0;

			.icon-bar {
				background: $color2-alt;
				width: 30px;
				height: 4px;
				margin-top: 7px;
			}

		}

	}
}
