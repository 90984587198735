// --------------------------------------------------------------------------------------------
// FLASH MESSAGE
// --------------------------------------------------------------------------------------------

.block-alerts {

	padding: 1.5rem 0;
	background: $center-bg;

	&.room {
		padding: 0;
		background: transparent;
	}

	.alert {

		margin-bottom: 0;

		+.alert {
			margin-top: 1rem;
		}

		&.alert-error {
			@include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);
		}
		&.alert-notice {
			@include alert-variant($alert-success-bg, $alert-success-border, $alert-success-text);
		}

	}

}