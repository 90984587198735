.current-offer-container {
	background-color: white;
}

.current-offer {
	margin-top: 3rem;
	margin-bottom: 2rem;

	@include breakpoint(tablet) {
		padding: 0 45px;
	}

	@include breakpoint(mobileonly) {
		margin-bottom: 0;
	}
}

.current-offer .viewport  {
	width: 98%;

	@include breakpoint(mobileonly) {
		width: 100%;
	}
}

.current-offer .carousel:before {
	content: '';
	position: absolute;
	top: -0.5rem;
	left: -0.5rem;
	display: block;
	width: 2rem;
	height: 2rem;
	background-color: $color8;
}

.current-offer .slide img {
	width: 100%;
}

.current-offer-title {
	@include font-size(34);
	position: relative;
	text-align: center;
	text-transform: unset;
	font-family: $font0;
	font-weight: bold;
	color: $color8 !important;

	span {
		position: relative;
		padding: 0.5rem 1rem;
		z-index: 2;
		background: white;
	}

	&:after {
		position: absolute;
		content: '';
		top: 30%;
		left: 0;
		width: 100%;
		height: 1px;
		z-index: 1;
		background-color: $color8;
	}
}

.current-offer-content-wrapper {
	display: flex;

	@include breakpoint(mobileonly) {
		flex-direction: column;
	}
}

.current-offer-content {
	flex: 1;
	padding: 1rem;
	padding-left: 0;

	@include breakpoint(mobileonly) {
		padding: 0;
		padding-top: 0.5rem;
		order: 2;
	}
}

.current-offer-city {
	@include font-size(22);
	padding: 0;
	margin-bottom: 0.5rem;
	text-transform: uppercase;
	font-weight: bold;
}

.current-offer-description {
	padding: 0;
	margin-bottom: 0.5rem;
	line-height: 1.5;
	font-weight: bold;
}

.current-offer-residence-link {
	display: inline-block;
	padding: 0.5rem;
	margin-bottom: 1rem;
	text-transform: uppercase;
	font-weight: bold;
	color: white;
	background-color: $color8;

	&:hover,
	&:focus {
		color: white;
	}
}

.current-offer-slidenav {
	flex-basis: 30%;
	flex-shrink: 0;
	padding: 0.5rem;
	background-color: $color8;


	@include breakpoint(mobileonly) {
		padding: 0.5rem;
		margin-top: 0;
		order: 1;
	}
	@include breakpoint(tablet) {
		padding-top: 15rem;
		margin-top: -14rem;
	}
	@include breakpoint(desktop) {
		padding-top: 23rem;
		margin-top: -22rem;
	}
}

.current-offer-slidenav .slidenav {
	display: flex;
	padding: 2rem 0;

	@include breakpoint(mobileonly) {
		padding: 0;
	}

}
.current-offer-slidenav .slidenav .thumb {
	float: none;
	width: unset;
}
