// --------------------------------------------------------------------------------------------
// FORMS
// --------------------------------------------------------------------------------------------

// Lists
// --------------------------------------------------------------------------------------------

$input-type-text-list:
"text",
"textarea",
"password",
"email",
"tel",
"url",
"search",
"date",
"datetime",
"number",
"range";

// Placeholders
// --------------------------------------------------------------------------------------------

%input {

	@include font-size(14);
	color: $gray-dark;
	font-weight: bold;
	height: auto;
	line-height: 1.7rem;
	border: 0;
	box-shadow: $input-box-shadow;

	&:focus {
		box-shadow: $input-box-shadow-focus;
	}

}

%error {
	@include font-size(13);
	display: block;
	font-weight: bold;
	padding: 0 0 .4em;
	line-height: 1em;
	color: $brand-danger;
}

.spin-control {

	@include group;
	display: inline-block;
	vertical-align: middle;
	border: 1px solid $color0;
	border-radius: $input-border-radius;
	background: $color0;

	.button {
		@include font-size(20);
		display: block;
		float: left;
		width: 30%;
		background: $color0;
		font-family: $font0;
		font-weight: 400;
		color: white;
		text-align: center;
		height: $spin-control-height;
		line-height: $spin-control-height;
	}

	.value {

		display: block;
		float: left;
		width: 40%;
		background: $input-bg;

		.control {

			@include font-size(15);
			font-family: $font0;
			font-weight: 400;
			display: block;
			width: 100%;
			text-align: center;
			height: $spin-control-height;
			line-height: $spin-control-height;
			background: none;
			border: 0;

		}

	}

}

// Styles
// --------------------------------------------------------------------------------------------

.form {

	@each $item in $input-type-text-list {

		input[type=#{$item}] {
			@extend %input;
		}

	}

	select, textarea {
		@extend %input;
	}

	@each $item in $icon-list {

		input.#{$item} {
			@include icon($name: $item, $bg-color: $input-bg, $bg-position: right 8px center);
			padding-right: 40px;
		}

	}

	input[type=date],
	input[type=datetime] {
		padding-right: 0;
	}

	.input-group-addon {
		@extend %input;
		background: $input-bg;
	}

	.input-group {

		box-shadow: $input-box-shadow;

		input {
			& {
				box-shadow: none;
			}
		}

		.input-group-addon {
			box-shadow: none;
			&:first-child {
				border-radius: $input-border-radius 0 0 $input-border-radius;
			}
			&:last-child {
				border-radius: 0 $input-border-radius $input-border-radius 0;
			}
		}

		&.isDate {

			background-color: white;

			input {
				background-color: white;
				cursor: default;
			}

			.input-group-addon {
				&:first-child {
					padding-right: 0;
				}
			}

			.glyphicon {
				@include font-size(18);
				color: $color2;
			}

		}

	}

	.radio, .checkbox {

		margin-top: 0;

		input[type="radio"],
		input[type="checkbox"] {
			position: relative;
			margin: .4em 0 0 0;
			width: auto;
			float: left;
			height: auto;
			border: 0;
			display: inline;
		}

		.label,
		label {
			@extend %label_checkbox;
		}

	}

	#account_country,
	#search_localisationId {
		padding: 10px 12px;
		appearance: none;
		background: #fff url(#{$img-path}select.png) no-repeat 97% center;
		@media screen and (-webkit-min-device-pixel-ratio:0) {
			padding: 5px 12px;
		}
		&[disabled] {
			background-color: #eeeeee;
		}
	}

	div.usertype {
		clear: left;
		#search_role {
			display: table;

			input {
				display: table-cell;
			}

			label {
				display: table-cell;
				padding-right: 20px;
				padding-left: 5px;
			}
		}

		body.is-logged & {
			display: none;
		}
	}

	div.accessibilite {
		body.is-logged & {
			clear: left;
		}
	}

	.form-group {
		.attachments {
			li {
				margin-bottom: 1rem;

				> div {
					display: inline-block;
					margin-right: 1rem;
				}
			}
		}
	}

	// Autocomplete
	.ui-select-container {

		width: auto !important;
		position: relative;

		> .form-control {
			&.ui-select-match {
				padding: 0;
				height: auto;
			}
		}

		.btn {
			@include font-size(14);
			font-family: $font-family-sans-serif;
			color: $gray-dark;
			font-weight: bold;
			text-transform: none;
			border: 0;
			background: none;
			padding: 18px 12px;
			line-height: 0;
		}

		.ui-select-choices {

			position: absolute;
			width: 100%;
			top: 100%;
			left: 0;
			background: $input-bg;
			border-radius: 0 0 $input-border-radius $input-border-radius;
			box-shadow: $input-box-shadow;
			z-index: $zindex-dropdown;

			.ui-select-choices-row {
				&.active {
					> a {
						background-color: $color2;
					}
				}
			}

		}
	}

	.add_attachment_link {
		@include icon ( "add", $type: 3, $bg-position: left center);
		padding-left: 25px;
		padding-bottom: 1%;
		padding-top: 1%;
	}

	.remove-attachment {
		@include icon ( "remove", $type: 3, $bg-position: left center);
		display: inline-block;
		width: 24px;
		height: 24px;
		line-height: 24px;
		vertical-align: bottom;

		span {
			position: absolute;
			width: 1px;
			height: 1px;
			margin: -1px;
			padding: 0;
			overflow: hidden;
			clip: rect(0, 0, 0, 0);
			border: 0;
		}
	}

	@include breakpoint(mobileonly) {
		.form-group {
			.attachments {
				div {
					margin-right: 0;
				}
			}
		}
	}

	@include breakpoint(tablet-landscape) {
		.dates-group {
			.form-control.ng-valid-date {
				padding-left: 4px;
				padding-right: 4px;
			}
			.col-xs-6 {
				padding-right: 5px;
				&:last-child {
					padding-left: 5px;
					padding-right: 15px;
				}
			}
			body.residence & {
				margin: 0 -35px;
			}
		}
	}

	@include breakpoint(desktop) {
		.dates-group {
			.form-control.ng-valid-date {
				padding-left: 6px;
				padding-right: 6px;
			}
			.col-xs-6 {
				padding-right: 15px;
				&:last-child {
					padding-left: 15px;
				}
			}
			body.residence & {
				margin: 0 -15px;
			}
		}
	}

	.form_errors {
		padding: 0;
		margin: 0;
		color: $brand-danger;
	}

	fieldset {

		legend {
			@extend %legend_label;
			&.dateSejour {
				margin-left: 15px;
			}
		}

	}

	div[ng-switch=datepickerMode] {
		table td {
			button {
				&.btn-default {
					@include hover {
						background: lighten($brand-primary, 10%) !important;
					}
				}
			}
			&.debut button,
			&.fin button,
			&.pendant button {
				background: lighten($brand-primary, 20%) !important;
				color: #fff;
				opacity: 1 !important;

			}
			&.pendant button {
				background: lighten($brand-primary, 30%) !important;
			}
			&.unavailabile button {
				background: #ccc !important;
				text-decoration: line-through;
			}
			&.today button {
				color: darken($color1, 15%) !important;
			}
		}

	}

}
