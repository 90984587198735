section#article {
	@include tight-row;
	margin: $grid-gutter-width 0 0 0;

	> .inner {
		@include make-row(0);
		border-radius: $border-radius-extra;
		background: $color6;
		box-shadow: $box-shadow-base;
		overflow: hidden;
		padding: ($grid-gutter-width / 2);

		ol,
		ul {
			padding: 0 0 1em 2em;
			li {
				list-style: square;
				padding: 0.1em 0;
			}
		}

		ol li {
			list-style: none;
			list-style-type: decimal;
		}
		h1 {
			padding-bottom: 0.7em;
		}
		
		h1,
		h2,
		h3,
		h4 {
			text-transform: uppercase;
		}
	}
}
