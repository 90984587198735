// --------------------------------------------------------------------------------------------
// LOGIN & REGISTER
// --------------------------------------------------------------------------------------------

body.login,
body.account,
body.forgotpassword,
body.resetpassword,
body.attachments,
body.my.bookings {

	.illustration {

		// Background-image is dynamically load in inline style in the view
		background: url(#{$img-path}examples/bg-login-example.jpg) no-repeat top center $center-bg;
		min-height: 459px;

		@include breakpoint(mobileonly) {
			background-size: auto 100%;
		}
		.form-tabs {
			margin-top: 0;
			.nav-tabs {
				top: 0;
			}
			.tab-content {
				border: 0;
			}
		}
	}

	.my-account,
	.block-multi-login {

		> .inner {
			margin: 2.5rem auto;
		}

	}

	.form-tabs {
		margin-top: $grid-gutter-width;
		.nav-tabs {
			position: relative;
			top: 1px;
		}
		.tab-content {
			border: 1px solid rgba(black, .1);
		}
	}

}

.classic-login {

	margin-bottom: 1.5rem;

	.label {
		@include font-size(19);
		display: block;
		font-weight: bold;
		color: $color0;
		line-height: 1rem;
		padding: 0 0 .5rem;
	}
	fieldset {
		legend {
			@include font-size(19);
		}
		#contact_role {
			> div {
				display: inline-block;
			}
			label {
				color: #333;
				margin-right: 20px;
				margin-left: 5px;
				@include font-size(14);
				float: right;
			}
		}
	}

	.pass-forgot {
		a {
			color: $color3;
			font-weight: bold;
			text-decoration: underline;
			line-height: 1.4em;
		}
	}

	.delete-account {
			@include group;
	}
	.booking {
		overflow: auto;
		zoom: 1;

		a {

			@include font-size(14);
			display: inline-block;
			color: $color4;
			font-style: italic;

			@include hover {
				text-decoration: underline;
			}

			&.connexion {
				font-weight: bold;
				float: right;
			}

		}

	}

	form.form-login .btn-submit {
		@include font-size(30);
	}

}

.social-login {

	> .inner {
		background: white;
		padding: ($padding-base-vertical * 4) ($padding-large-horizontal * 2);
		border-radius: $border-radius-extra;
	}

	.title {
		@include font-size(21);
		font-family: $font1;
		color: $color3;
		line-height: 1em;
		padding: 0 0 .7em;
	}

	p {
		@include font-size(14);
		line-height: 1.3em;
		&, a {
			color: $color3;
		}
		a {
			text-decoration: underline;
		}
	}
	.btn {
		color: #fff;
		text-decoration: none;
	}

	.api-actions {
		padding: 0 0 1em;
	}

}

.block-arguments {
	.arguments {

		.item {

			.title {
				margin: 0;
			}

			.p {
				display: none;
			}

		}

	}

}


table.bookings {
	width: 100%;
	> thead > tr > th {
		@extend %legend_label;
		@include font-size(14);
		display: table-cell;
		vertical-align: bottom;
		text-align: center;
		padding: 7px 0;
		border-bottom: 2px solid $color0;
	}
	tr {
		td {
			border-right: 2px solid #f0f0f0;
			border-bottom: 2px solid #f0f0f0;
			padding: 7px;
		}
		td:last-child {
			border-right-color: transparent;
		}
		&:last-child td {
			border-bottom: 0;
		}
	}
}

table.attachments {
	margin-top: 20px;
	overflow-x: auto;

	tr {
		td {
			text-align: center;
		}
	}
}


// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// BREAKPOINTS
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// desktop
// ------------------------------------------------------------------------------------
@include breakpoint(desktop) {

	.block-multi-login {

		> .inner {
			width: 85%;
		}

	}

	.classic-login {

		margin-bottom: 0;

		.pass-forgot {
			float: right;
		}

	}

	.block-arguments {

		.arguments {

			.item {
				> .inner a {
					min-height: 75px;
				}
				&.item-4 a span {
					line-height: 45px;
				}
			}

		}

	}

} // desktop end
