// External fonts
@import url("//fonts.googleapis.com/css?family=Ubuntu:300,400,700|Ubuntu+Condensed|Ubuntu+Mono:400,700");

// Vendors
@import "vendor/bootstrap";

// Utilities
@import "partials/variables"; 	// Custom variables
@import "partials/mixins"; 		// Custom mixins
@import "partials/placeholders";	// Custom generic placeholders
@import "partials/commons";	// Commons styles

// Internal fonts
@include font-face("Bebas Neue", "BebasNeue", "sans-serif");
@include font-face("MontserratAlternates-Bold", "MontserratAlternates-Bold", "sans-serif");
@include font-face("MontserratAlternates-Regular", "MontserratAlternates-Regular", "sans-serif");

	// Components
	@import "partials/components/blocks";
	@import "partials/components/alerts";
	@import "partials/components/forms";
	@import "partials/components/buttons";
	@import "partials/components/titles";
	@import "partials/components/tabs";
	@import "partials/components/carousel";
	@import "partials/components/arguments";
	@import "partials/components/bandeau_cnil";
	@import "partials/components/current_offer";

	// Structure
	@import "partials/structure/header";
	@import "partials/structure/footer";
	// @import "partials/structure/navs";

	// Templates
	@import "partials/templates/home";
	@import "partials/templates/login";
	@import "partials/templates/residence";
	@import "partials/templates/checkout";
	@import "partials/templates/booking";
	@import "partials/templates/article";
	@import "partials/templates/contact";
	@import "partials/templates/support";

	// App
	@import "partials/app";
