// --------------------------------------------------------------------------------------------
// TABS
// --------------------------------------------------------------------------------------------

.tabs {

	margin: 0 0 $grid-gutter-width;
	@include tight-row;

	> .inner {
		background: $tabs-bg;
	}

	.wrapper {
		@include make-row($tabs-gutter-width);
	}

	.tab-list {

		@include make-xs-column(9, $tabs-gutter-width);
		@include group;

		.tab {

			display: block;
			float: left;
			border-right: 1px solid lighten($tabs-bg, 15%);

			@include breakpoint(mobileonly) {
				&:last-child {
					border-right: 0;
				}
			}

			> .link {

				@include font-size(22);
				@include transition;
				display: block;
				font-family: $font1;
				color: $tabs-color;
				line-height: ( $tabs-height - $tabs-border-width );
				text-transform: uppercase;
				padding: 0 .8rem;

				@include hover {
					background-color: darken($tabs-bg, 5%);
				}

				> span {
					display: block;
					border-bottom: $tabs-border-width solid transparent;
				}

			}

			&.active {
				> .link {
					> span {
						border-color: $tabs-active-border-color;
					}
				}
			}

		}

	}

	.tab-actions {

		@include make-xs-column(3, $tabs-gutter-width);

		.item {
			display: block;
			float: right;
		}

		.btn {
			@include font-size(24);
			line-height: $tabs-height;
			padding-top: 0;
			padding-bottom: 0;
		}

	}

	// Position Fixed
	body.tabs-fixed & {

		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		box-shadow: $box-shadow-base;

		> .inner {
			@include container-fixed;
			//@extend .container;
			padding: 0;
		}

	}
	&.fixed {

		z-index: 1000;
		top: 0;
		box-shadow: $box-shadow-base;

	}

	// Tablet
	// ------------------------------------------------------------------------------------
	@include breakpoint(tablet) {

		.tab-list {

			.tab {
				> .link {
					padding: 0 1.5rem;
				}
			}

		}

		.tab-actions {

			.btn {
				padding: 0 ($padding-large-horizontal * 3);
			}

		}

	} // tablet end

} // tabs end

.form-tabs {

	.nav-tabs {

		> li {

			> a {
				@include font-size(22);
				font-family: $font1;
				background: $color2;
				color: white;
				padding: $padding-base-vertical ($padding-large-horizontal * 2);
			}

			+ li {
				margin-left: 8px;
			}

			&.active {

				> a {
					background-color: rgba(white, .8);
					color: $color2;
				}

			}

		}

		@include breakpoint(mobileonly) {
			> li {
				float: none;

				+ li {
					margin-left: 0;
				}

				&.active {
					> a {
						border-color: $color2;
						border-width: 2px;
					}

				}
			}
		}

	}

	.tab-content {

		background: rgba(white,.8);
		padding: ($padding-base-vertical * 4) ($padding-large-horizontal * 2);

		.tab-pane {
			overflow-x: auto;
		}

	}

}
