// --------------------------------------------------------------------------------------------
// Arguments
// --------------------------------------------------------------------------------------------

.arguments {
	padding: 20px 0;

	.item {

		> .inner {
			a {
				width: 100%;
				min-height: 160px;
				padding: ($grid-gutter-width / 2);
				padding-top: 100px;
				color: $color3;
				text-decoration: none;
				display: inline-block;
			}
		}

		.title {
			@include font-size(16);
			font-family: $font2;
			text-transform: uppercase;
			text-align: center;
			line-height: 1em;
			margin: 0 0 .3em;
			display: block;
		}

		span.p {
			@include font-size(14);
			font-family: $font0;
			line-height: 1.2em;
			padding-bottom: 0;
			display: block;
			text-align: center;
		}

		&.item-1 {
			> .inner a {
				@include icon($name: trip, $bg-image: false, $type: 4, $bg-position: 50% 10%);

				@include hover {
					@include icon($name: trip, $bg-image: false, $type: 4, $bg-position: 50% 10%);
				}
			}
		}

		&.item-2 {
			> .inner a {
				@include icon($name: location, $bg-image: false, $type: 4, $bg-position: 50% 10%);

				@include hover {
					@include icon($name: location, $bg-image: false, $type: 4, $bg-position: 50% 10%);
				}
			}
		}

		&.item-3 {
			> .inner a {
				@include icon($name: couverture, $bg-image: false, $type: 4, $bg-position: 50% 10%);

				@include hover {
					@include icon($name: couverture, $bg-image: false, $type: 4, $bg-position: 50% 10%);
				}
			}
		}
	}

}
