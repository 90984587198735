// --------------------------------------------------------------------------------------------
// BUTTONS
// --------------------------------------------------------------------------------------------

%btn-has-icon {
	display: block;
	background: none no-repeat 0 center;
	padding-left: 40px;
}

.btn {

	@include transition;
	@include font-size($btn-font-size);
	font-family: $font1;
	text-transform: uppercase;
	border: 0;
    white-space: initial;

    // boutons en dessous du datepicket
	li[ng-if=showButtonBar] & {
		@include font-size(16);
		// suppression du bouton 'supprimer'
		&.btn-danger {
			display: none;
		}
	}

	@for $i from 0 through $nb-colors {

		&.type-#{$i} {
			@extend %bgcolor#{$i} !optional;
		}

		&.type-#{$i}-alt {
			@extend %bgcolor#{$i}-alt !optional;
		}

	}

	// Exception
	&.type-1 {
		@extend %bgcolor1-alt;
	}

	@each $item in $icon-list {

		&.btn-#{$item} {

			> span {

				@include icon($name: $item, $bg-image: true);
				@extend %btn-has-icon;

			}
		}

	}

}